.MainBackground {
  background-color: var(--color-primary-0);
  min-height: 100vh;
  background-image: url("/resources/Hexagon.svg");
  /* background-repeat:repeat; */
}

:root {
  color:white
}


.login {
  /* background-color: white; */
  height: 100%;
  display: flex;
  flex-direction: column;
}

h1.title {
  background-color: var(--color-primary-4);
  border: 5px solid var(--color-primary-1);
  padding: 20px;
  padding-left: var(--corner-px);
  width: calc(45vw - var(--corner-px));
  
  clip-path: polygon(
    0 0,
    calc(100% - var(--corner-px)) 0,
    100% 100%,
    var(--corner-px) 100%
  );

  --corner-px: 50px;
}


button {
  background-color: var(--color-primary-2);
  border: 3px;
  border-style: groove;
  color: white;
  border-color: var(--color-primary-4);
}

.clickable:hover {
  filter: brightness(1.3);
  cursor: pointer;
}

.clickable:active {
  filter: brightness(1.5);
}

button:hover {
  filter: brightness(1.3);
  cursor: pointer;
}

button:active {
  filter: brightness(1.5);
}

.four-corner-cut {
  --corner-px: 10px;

  clip-path: polygon(
    var(--corner-px) 0%,
    calc(100% - var(--corner-px)) 0%,
    100% var(--corner-px),
    100% calc(100% - var(--corner-px)),
    calc(100% - var(--corner-px)) 100%,
    var(--corner-px) 100%,
    0% calc(100% - var(--corner-px)),  
    0% var(--corner-px)
  );
}

button.corner-cut {
  --corner-cut: 10px;

  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--corner-cut)),
    calc(100% - var(--corner-cut)) 100%,
    var(--corner-cut) 100%,
    0 calc(100% - var(--corner-cut))
  )
}

.secondary-button {
  background-color: var(--color-secondary-1-0);
  border-color: var(--color-secondary-1-3);
}

.secondary-button.active{
  /* color:  var(--color-secondary-1-1) ; */
  background-color: var(--color-secondary-1-1);
  /* border-color: var(--color-secondary-1-1); */
}

.negative-button {
  background-color: var(--color-secondary-2-0);
  border-color: var(--color-secondary-2-3);
}

.negative-button.active{
  /* color:  var(--color-secondary-2-1) ; */
  background-color: var(--color-secondary-2-1);
  /* border-color: var(--color-secondary-2-1); */
}

.link-button {
  background-color: var(--color-primary-3);
  width: 100% !important; /* This is here for the Link to fill the full space */
  height: 100%;
}

input {
  color: white;
  background-color: var(--color-primary-4);
  border-style: solid;
  border-color: var(--color-primary-1);
  border-color: black;
  color-scheme: dark;
}

textarea {
  color: white;
  background-color: var(--color-primary-4);
  border-color: black;
  color-scheme: dark;
}

select {
  background-color: var(--color-primary-0);
  color: white;
  border-color: black;
}

a {
  text-decoration: none;
  color: white;
}

a:visited {
  color: white;
}

a:hover{
  text-decoration: underline;
}

/* Reusable stuff */
.active-green {
  background-color: var(--color-secondary-1-0) !important;
}

/* Default Inputs */
.input-wrapper.checkbox-input {
  position: relative;
  display: flex;
  flex-direction: row;
  /* background-color: black; */
}

.input-wrapper.checkbox-input:hover{
  filter: brightness(1.1);
}

.input-wrapper.checkbox-input label{
  /* not sure why this is needed but otherwise switch is out of line */
  /* margin-right: 0px;*/ 
  --knob-width: 30px; 
}

.input-wrapper.checkbox-input .toggler-slider{
  
  background-color: var(--color-secondary-2-0);
  border-radius: 0;
  border: 2px solid black;

  position: relative;
  width: calc(var(--knob-width)*2 + 10px );
  height: 80%;
  height: 20px;
  transition: all 100ms ease;
}

.input-wrapper.checkbox-input .toggler-knob{
  width: var(--knob-width);
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--color-primary-0);
  position: absolute;
  transition: all 100ms ease;
}

.input-wrapper.checkbox-input .toggler-knob:hover{
  filter: brightness(1.3);
}

input[type="checkbox"]:checked+.toggler-slider {
  /* border-color: var(--color-primary-0); */
  background-color: var(--color-primary-4);
}

input[type="checkbox"]:checked+.toggler-slider .toggler-knob {
  left: calc(100% - var(--knob-width));
  border-color: var(--color-primary-0);
}

/* Radio Buttons */
.input-wrapper.radio-buttons .radio-label{
  background-color: var(--color-secondary-1-0);
  border: 2px solid black;
  
  padding: 3px;
  text-align: center;
  margin: 0 3px;
  
  user-select: none;

  --corner-cut: 5px;

  clip-path: polygon(
    var(--corner-cut) 0,
    calc(100% - var(--corner-cut)) 0,
    100% var(--corner-cut),
    100% calc(100% - var(--corner-cut)),
    calc(100% - var(--corner-cut)) 100%,
    var(--corner-cut) 100%,
    0 calc(100% - var(--corner-cut)),
    0 var(--corner-cut)
  )
}

.input-wrapper.radio-buttons .radio-label:hover {
  filter: brightness(1.3);
}

.input-wrapper.radio-buttons input[type="radio"]:checked+label {
  background-color: var(--color-primary-4);
}

.error-block {
  align-self: center;
  color: var(--color-secondary-2-1);
  margin-bottom: 10px;
}