/* CSS - Cascading Style Sheet */
/* Palette color codes */
/* Palette URL: http://paletton.com/#uid=34F1j0kuiiUvNtru4nlwudexs7O */

/* Feel free to copy&paste color codes to your application */


/* As hex codes */

.color-primary-0 { color: #420B65 }	/* Main Primary color */
.color-primary-1 { color: #640A9D }
.color-primary-2 { color: #520E7C }
.color-primary-3 { color: #2D0447 }
.color-primary-4 { color: #1A022A }

.color-secondary-1-0 { color: #056349 }	/* Main Secondary color (1) */
.color-secondary-1-1 { color: #019A6F }
.color-secondary-1-2 { color: #077A5A }
.color-secondary-1-3 { color: #004532 }
.color-secondary-1-4 { color: #00291D }

.color-secondary-2-0 { color: #974F08 }	/* Main Secondary color (2) */
.color-secondary-2-1 { color: #EB7502 }
.color-secondary-2-2 { color: #BA620B }
.color-secondary-2-3 { color: #693400 }
.color-secondary-2-4 { color: #3E1F00 }


.background-color-primary-0 { background-color: #420B65 }	/* Main Primary color */
.background-color-primary-1 { background-color: #640A9D }
.background-color-primary-2 { background-color: #520E7C }
.background-color-primary-3 { background-color: #2D0447 }
.background-color-primary-4 { background-color: #1A022A }

.background-color-secondary-1-0 { background-color: #056349 }	/* Main Secondary color (1) */
.background-color-secondary-1-1 { background-color: #019A6F }
.background-color-secondary-1-2 { background-color: #077A5A }
.background-color-secondary-1-3 { background-color: #004532 }
.background-color-secondary-1-4 { background-color: #00291D }

.background-color-secondary-2-0 { background-color: #974F08 }	/* Main Secondary color (2) */
.background-color-secondary-2-1 { background-color: #EB7502 }
.background-color-secondary-2-2 { background-color: #BA620B }
.background-color-secondary-2-3 { background-color: #693400 }
.background-color-secondary-2-4 { background-color: #3E1F00 }


/* As RGBa codes */

.rgba-primary-0 { color: rgba( 66, 11,101,1) }	/* Main Primary color */
.rgba-primary-1 { color: rgba(100, 10,157,1) }
.rgba-primary-2 { color: rgba( 82, 14,124,1) }
.rgba-primary-3 { color: rgba( 45,  4, 71,1) }
.rgba-primary-4 { color: rgba( 26,  2, 42,1) }

.rgba-secondary-1-0 { color: rgba(  5, 99, 73,1) }	/* Main Secondary color (1) */
.rgba-secondary-1-1 { color: rgba(  1,154,111,1) }
.rgba-secondary-1-2 { color: rgba(  7,122, 90,1) }
.rgba-secondary-1-3 { color: rgba(  0, 69, 50,1) }
.rgba-secondary-1-4 { color: rgba(  0, 41, 29,1) }

.rgba-secondary-2-0 { color: rgba(151, 79,  8,1) }	/* Main Secondary color (2) */
.rgba-secondary-2-1 { color: rgba(235,117,  2,1) }
.rgba-secondary-2-2 { color: rgba(186, 98, 11,1) }
.rgba-secondary-2-3 { color: rgba(105, 52,  0,1) }
.rgba-secondary-2-4 { color: rgba( 62, 31,  0,1) }



/* Generated by Paletton.com © 2002-2014 */
/* http://paletton.com */


/* https://paletton.com/#uid=54F0u0kuiiUvNtru4nlwudexs7O */
/* https://paletton.com/#uid=54F0Z0kuiiUvNtru4nlwudexs7O */
/* https://paletton.com/#uid=34F1j0kFZr5JZPhKlyCD4mlCVhv */
/* https://paletton.com/#uid=54F0Z0kFccSvg88DJ9VBOjtEyqu */
/* https://paletton.com/#uid=34F1j0kuiiUvNtru4nlwudexs7O */ /*Using This */

:root {
    --main-color: #663486;
    --main-purple: #420B65;
    --old-purple: #2e2136;
  
    --color-primary-0:  #420B65;	/* Main Primary color */
    --color-primary-1:  #640A9D;
    --color-primary-2:  #520E7C;
    --color-primary-3:  #2D0447;
    --color-primary-4:  #1A022A;

    
    --color-secondary-1-0:  #056349;	/* Main Secondary color (1) */
    --color-secondary-1-1:  #019A6F;
    --color-secondary-1-2:  #077A5A;
    --color-secondary-1-3:  #004532;
    --color-secondary-1-4:  #00291D;
    
    --color-secondary-2-0:  #974F08;	/* Main Secondary color (2) */
    --color-secondary-2-1:  #EB7502;
    --color-secondary-2-2:  #BA620B;
    --color-secondary-2-3:  #693400;
    --color-secondary-2-4:  #3E1F00;
  
  
}