
.header-shadow {
  filter: 
    drop-shadow( 3px 4px 2px black)
    drop-shadow( 3px 4px 2px black)
}

.game-display h1 {
  background-color: var(--color-primary-4);
  color: white;

  border: 5px;
  border-style: solid;
  /* border-style: groove; */

  border-color: var(--color-primary-1);
  --corner-px: 50px;
  clip-path: polygon(
    var(--corner-px) 0,
    100% 0,
    calc(100% - var(--corner-px)) 100%,
    0 100%
  );
  padding: 5px;
  padding-left: 55px;
}


.game-display img {
  width: 100%;
  display: block;
}

.game-display .image-panel {
  margin-bottom: 20px;
  position: relative;
  border: 3px;
  border-style: groove;
  /* border-style: solid; */
  border-color: var(--color-primary-1);
  /* border-color: black; */
  filter: 
  drop-shadow( 4px 5px 5px black)
  drop-shadow( 4px 6px 5px black);
}

.game-display .image-panel .carousel-button{
  border-style: none;
  font-size: 1em;
  height: 32px;
  /* padding: 10px; */
  background-color: var(--color-primary-2);
  color: white;
}


.game-display .image-panel .add {
  position: absolute;
  left:50%;
  top:0;
  padding:10px;
  width: 75px;
  /* height: 52px; */
  --corner-px: 20px;

  clip-path: polygon(
    0 0, 
    100% 0%, 
    calc(100% - var(--corner-px)) 100%,
    var(--corner-px) 100%
  );
  transform: translateX(-50%);
}

.game-display .image-panel .add i {
  margin: auto;
  width:15px;
  height:15px;
}

.game-display .image-panel .prev {
  position: absolute;
  /* background-color: red; */
  bottom: 0;
  left: 0;
  padding-right: 50px;
  clip-path: polygon(
    0 0,
    calc(100% - 20px) 0,
    100% 100%,
    0 100%
  );
}

.game-display .image-panel .next {
  /* background-color: blue; */
  position: absolute;
  bottom: 0;
  right: 0;
  padding-left: 50px;
  clip-path: polygon(
    20px 0,
    100% 0,
    100% 100%,
    0 100%
  );
}

.game-display .image-panel .position {
  position: absolute;
  /* background-color: green; */
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding-left: 50px;
  padding-right: 50px;
  clip-path: polygon(
    20px 0,
    calc(100% - 20px) 0,
    100% 100%,
    0 100%
  );
}


.game-display .image-panel .carousel-indicator {
  background-color: white;
  height: 8px;
  /* border: 1px; */
  border-color: black;
  border-style: outset;
}

.game-display .image-panel .carousel-indicator.active {
  background-color: var(--color-secondary-1-1);
}

.game-display .image-panel .upload-image-modal{
  position: absolute;
  top: 0;
  /* left: 0; */
  --padding-px: 25px;
  padding: var(--padding-px);
  padding-top: 50px;

  width: calc(100% - (var(--padding-px) * 2));
  height: calc(100% - (var(--padding-px) * 2 + 25px));
  background-color: var(--color-primary-4);
}

.game-display .image-panel .upload-image-modal form {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%; 
}


.upload-image-modal .input-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  font-size: 1.3em;

}

.upload-image-modal label{
  width:20%
}

.upload-image-modal input{
  background-color: var(--color-primary-0);
}

.upload-image-modal button{
  margin-top: auto;
  /* background-color: var(--color-primary-0) */
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* width:80% */
  padding: 10px;
}

.game-display .row{
  margin-bottom:20px;
  display: flex;
  flex-direction:row;
  justify-content: space-between;
}

.game-display .label-parent {
  width: 50%;
  display: flex;
  justify-content:space-around;

  filter:
      drop-shadow(1px 1px 0px black)
      drop-shadow(-1px 1px 0px black)
      drop-shadow(1px -1px 0px black)
      drop-shadow( 3px 4px 2px black)
      drop-shadow( 3px 4px 2px black)
}

.game-display .label {
  /* text-align: right; */
  width: 38%;
  background-color: var(--color-primary-1);
  color: white;
  padding: 5px;
  padding-right: 20px;
  font-size: 1em;
  clip-path: polygon(
    0 0,
    100% 0,
    calc(100% - 20px) 100%,
    0 100%
  );
  padding-top: 10px;
  padding-bottom: 10px;

}

.game-display .data {
  /* text-align: right; */
  width: 38%;
  background-color: var(--color-primary-2);
  color: white;
  padding: 5px;
  padding-left: 20px;
  font-size: 1em;
  clip-path: polygon(
    20px 0,
    100% 0,
    100% 100%,
    0 100%
  );
  padding-top: 10px;
  padding-bottom: 10px;
}


.game-display input {
  width: calc(100% - (5px * 2));
}

.game-display .input {
  width: 38%;
  background-color: var(--color-primary-2);
  color: white;
  padding: 5px;
  padding-top: 10px;
  padding-left: 20px;
  clip-path: polygon(
    20px 0,
    100% 0,
    100% 100%,
    0 100%
  );
}

.game-display .action-buttons {
  --component-width:18%;
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;
  justify-content: flex-end;
  width:100%;
  /* flex-direction: row; */
  filter: 
    drop-shadow( 4px 4px 2px black)
    drop-shadow( 4px 4px 2px black)
}

.game-display .action-buttons button {
  padding: 10px;
  font-size: 1em;
  border-style: solid;
  border-width: 3px;
  width: var(--component-width);

  --corner-cut: 15px;

  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--corner-cut)),
    calc(100% - var(--corner-cut)) 100%,
    var(--corner-cut) 100%,
    0 calc(100% - var(--corner-cut))
  );
}

.game-display .action-buttons .transition-div {
  width: var(--component-width);
}

.game-display .action-buttons a {
  /* padding: 10px; */
  /* font-size: 1em; */
  /* border-style: solid; */
  /* border-width: 3px; */
  width: var(--component-width);
}


/* delete dialog box */
.game-display .confirm-dialog {
  position: absolute;
  display: flex;
  flex-direction: column;
  transform: translate(-6px,-100%);
  background-color: var(--color-primary-4);
  border: 3px solid var(--color-primary-1);
  border-bottom: 0px;
  color: white;
  width: var(--component-width);
  text-align: center;
  padding:3px;

  --corner-cut: 15px;

  clip-path: polygon(
    0 var(--corner-cut),
    var(--corner-cut) 0,
    calc(100% - var(--corner-cut)) 0,
    100% var(--corner-cut),
    100% 100%,
    0 100%
  ); 
}

.game-display .confirm-dialog button{
  margin-top: 3px;
  width: 100%;
}


/* Transition dialog box */
.game-display .transition-div .transition-toggle{
  width: 100%;
  height: 100%;
}

.game-display .transition-dialog {
  position: absolute;
  display: flex;
  flex-direction: column;
  transform: translate(-6px,-100%);
  background-color: var(--color-primary-4);
  border: 3px solid var(--color-primary-1);
  border-bottom: 0px;
  color: white;
  width: var(--component-width);
  text-align: center;
  padding:3px;

  --corner-cut: 15px;

  clip-path: polygon(
    0 var(--corner-cut),
    var(--corner-cut) 0,
    calc(100% - var(--corner-cut)) 0,
    100% var(--corner-cut),
    100% 100%,
    0 100%
  );
}

.game-display .transition-dialog p{
  /* text-align: center; */
  /* width: 100%; */
  margin-top: 6px;
}

.game-display .transition-dialog button{
  margin-top: 3px;
  width: 100%;
}

.game-display .transition-dialog select {
  font-size: 1.1rem;
}