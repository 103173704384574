/* List Properties */
.game-list-items {
  /* work out the actual values for this instead of guessing */
    height: calc(100vh - var(--panel-top-margin) - 10px - 30px - 80px);
    overflow: auto;
}

/* Game Card */
.game-card-shadow {
  filter: 
    drop-shadow( -1px -1px 0px black)
    drop-shadow( 3px 4px 2px black)
    drop-shadow( 3px 4px 2px black)
}

.game-card {
    background-color: var(--color-primary-4);
    
    font-size: 1em;
    color: white;
    
    border: 5px;
    border-style: solid;
    /* border-style: groove; */
    border-color: var(--color-primary-1);
    
    margin-top: 5px;
    margin-left: 2px;
    margin-bottom: 12px;
    margin-right: 15px;

    padding: 10px;
    height: 3em;
    line-height: 3em;
  
    --corner-px: 15px;
    clip-path: polygon(
      var(--corner-px) 0%,
      calc(100% - var(--corner-px)) 0%,
      100% var(--corner-px),
      100% calc(100% - var(--corner-px)),
      calc(100% - var(--corner-px)) 100%,
      var(--corner-px) 100%,
      0% calc(100% - var(--corner-px)),  
      0% var(--corner-px)
    );
  }

.game-card:hover {
  background-color: var(--color-secondary-1-4);
}

.game-card.active {
  border-color: var(--color-secondary-1-0);
}
  
.game-card .Date {
/* text-align: right; */
float: right;
}
 

/* Search Bar */
.game-list-panel .search-bar input{
  /* width: calc(70% - (var(--padding-px) * 2)); */
  font-size: 1.5em;
  flex-grow: 1;
  color: white;
  border-right: 0px;
  background-color: var(--color-primary-4);
  border-style: solid;
  border-color: var(--color-primary-1);
  border-color: black;
  --corner-cut:10px;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--corner-cut)),
    calc(100% - var(--corner-cut)) 100%,
    var(--corner-cut) 100%,
    0 calc(100% - var(--corner-cut))
  );
}

.game-list-panel .search-bar{
  margin-bottom:10px;
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  filter: 
    drop-shadow( 3px 4px 2px black)
    drop-shadow( 3px 4px 2px black)
}

.game-list-panel .search-bar button {
  font-size: 1.5em;
}

.game-list-panel .search-bar .clear {
  /* border-left: 0px; */
  
  --corner-cut:10px;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--corner-cut)),
    calc(100% - var(--corner-cut)) 100%,
    var(--corner-cut) 100%,
    0 calc(100% - var(--corner-cut))
  );
}

.game-list-panel .search-bar .add {
  padding: 10px;
  margin-left: 10px;
  --corner-cut:10px;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--corner-cut)),
    calc(100% - var(--corner-cut)) 100%,
    var(--corner-cut) 100%,
    0 calc(100% - var(--corner-cut))
  );
}

.game-list-panel .search-bar .add.active {
  background-color: var(--color-secondary-1-0);
}