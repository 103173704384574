.home-page {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 20px;
  overflow: auto;
}

.action-block {
  filter: 
    drop-shadow( 4px 5px 5px black)
    drop-shadow( 4px 6px 5px black);
}

.action-block h2 {
  background-color: var(--color-primary-4);
  border: 5px solid var(--color-secondary-1-0);
  padding: 20px;
  width: 15vw;
  text-align: center;
}

.action-block h2:hover {
  filter: brightness(1.3);
  cursor: pointer;
}

.queue-toggle-block {
  position:absolute;
  align-self: flex-end;
  z-index: 2;

  top:50px;

  filter: 
    drop-shadow( -4px 5px 5px black)
    drop-shadow( -4px 6px 5px black);
}


.queue-toggle {
  
  --slash-px:calc((30vw) / 10);
  --corner-px: 20px;
  clip-path: polygon(
    0 0,
    calc(100% - var(--corner-px)) 0,
    100% var(--corner-px),
    100% calc(100% - var(--corner-px)),
    calc(100% - var(--corner-px)) 100%,
    var(--slash-px) 100%
  );
}

.currently-playing {
  height: 75vh;
  display: flex;
  flex-flow: column-reverse;
}


.home-page a.memory-card-link  {
  text-decoration: none;
  color: white;
}

.home-page h2.memory-card-link {
  

  --slash-px:calc((30vw) / 10);
  --corner-px: 20px;

  clip-path: polygon(
    var(--corner-px) 0,
    calc(100% - var(--slash-px)) 0,
    100% 100%,
    var(--corner-px) 100%,
    0 calc(100% - var(--corner-px)),
    0 var(--corner-px)
  );
}

.home-page .title {
  filter: 
    drop-shadow( 4px 5px 5px black)
    drop-shadow( 4px 6px 5px black);

  margin-bottom: 20px;
}

.home-page .title h1 {
  background-color: var(--color-primary-4);
  border: 5px solid var(--color-secondary-1-0);
  padding: 20px;
  padding-left: var(--corner-px);
  width: calc(45vw - var(--corner-px));
  
  clip-path: polygon(
    0 0,
    calc(100% - var(--corner-px)) 0,
    100% 100%,
    var(--corner-px) 100%
  );

  --corner-px: 50px;
}