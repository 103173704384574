.new-game {
    height: 100%;
    position: relative;
}

.new-game form{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content:flex-start;
    height: 100%;
    /* width: 100%; */
}

.new-game .input-wrapper {
    /* width: 100%; */
    margin-bottom: 10px;
    font-size: 1.1em;
    display: flex;
    width: 100%;
}

.new-game label{
    width: 50%;
    margin-right: 10px;
}


.new-game input{
    /* font-size: 1.5em; */
    font-size: 1.1em;
    width: 50%;
    background-color: var(--color-primary-0);
    align-self: flex-end;
}

.new-game select {
    width: 50%;
    font-size: 1.1em;
    background-color: var(--color-primary-0);
    border-color: black;
    border-width: 2px;
    color: white;
}

.new-game .input-wrapper.select-input label{
    /* not sure why this is needed but otherwise select is out of line */
    margin-right: 0px; 
}

.new-game .input-wrapper.checkbox-input {
    position: relative;
    display: flex;
    flex-direction: row;
    /* background-color: black; */
}

.new-game .input-wrapper.checkbox-input label{
    /* not sure why this is needed but otherwise switch is out of line */
    margin-right: 0px; 
    --knob-width: 30px;
}

.new-game .input-wrapper.checkbox-input .toggler-slider{
    
    background-color: var(--color-secondary-2-0);
    border-radius: 0;
    border: 2px solid black;

    position: relative;
    width: calc(var(--knob-width)*2 + 10px );
    height: 80%;
    transition: all 100ms ease;
}

.new-game .input-wrapper.checkbox-input .toggler-knob{
    width: var(--knob-width);
    height: 100%;
    left: 0;
    top: 0;
    background-color: var(--color-primary-0);
    position: absolute;
    transition: all 100ms ease;
}

.new-game input[type="checkbox"]:checked+.toggler-slider {
    /* border-color: var(--color-primary-0); */
    background-color: var(--color-primary-1);
}

.new-game input[type="checkbox"]:checked+.toggler-slider .toggler-knob {
  left: calc(100% - var(--knob-width));
  border-color: var(--color-primary-0);
}


.new-game .control-buttons {
    position: absolute;
    bottom:0px;
    align-self: flex-end;
    display: flex;
    flex-direction:column;
    width: 100%;
}

.new-game .control-buttons button{
    padding: 10px;
}

.new-game .input-wrapper.date-input label{
    /* not sure why this is needed but otherwise date is out of line */
    margin-right: 5px; 
}
