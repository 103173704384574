.default-fall-back,.not-found {
    display: flex;
    /* width: 80%; */
    /* height: 100vh; */
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.default-fall-back .content,.not-found .content{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 80%;
    background-color: var(--color-primary-4);
    border: 7px solid var(--color-primary-1);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 5rem;
    --corner-px:50px;
}

.default-fall-back a,.not-found a{
    text-decoration: underline;
    color: var(--color-secondary-1-1);
}


