.login {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    filter: 
    drop-shadow( 0px 4px 2px black)
    drop-shadow( 0px 4px 2px black)
}

.login form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 3rem;

    padding: 25px;
    border: 5px solid var(--color-primary-4);
    background-color: var(--color-primary-0);
}

.login form label {
    margin-bottom: 10px;
    width: 100%;
    white-space: pre-wrap;
}

.login form input{
    font-size: 3rem;
    box-sizing: border-box;
    width: 70%;
    margin-left: auto;
}

.login form button{
    font-size: 3rem;
}