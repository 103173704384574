.currently-playing .game{
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    /* for use in child components */
    --vertical-padding: 15px;
}

.currently-playing .game-block{
    /* height: 100%; */
    width: 100%;
}

.currently-playing .game.open{
    z-index: 1;
}

.currently-playing .game:nth-child(even) {
    filter: 
    drop-shadow( 4px 5px 5px black)
    drop-shadow( 4px 6px 5px black);
}

.currently-playing .game:nth-child(odd) {
    filter: 
    drop-shadow( -4px 5px 5px black)
    drop-shadow( -4px 6px 5px black);
}


/* HEADER */
.currently-playing .game h1{
    background-color: var(--color-primary-4);
    border: 5px solid var(--color-primary-1);
  
    /* I think this is for when theres a background */
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    
    height: 100%;
    width: 50%;
    /* --vertical-padding: 10px; */
    --horizontal-padding: 5px;
    padding: var(--vertical-padding) var(--horizontal-padding);
    font-size: 3em;
    user-select: none;
  
    display: flex;
    align-items: center;
    justify-content: center;

    --arrow-px: 10%;
    --corner-px: 40px;
}

.currently-playing .game h1:hover  {
    filter: brightness(1.3);
    cursor: pointer;
}

.currently-playing .game:nth-child(even) h1 {
    clip-path: polygon(
      var(--corner-px) 0,
      calc(100% - var(--arrow-px)) 0,
      100% 50%,
      calc(100% - var(--arrow-px)) 100%,
      var(--corner-px) 100%,
      0 calc(100% - var(--corner-px)),
      0 var(--corner-px)
    );
    border-right: none;
}

.currently-playing .game:nth-child(odd) h1 {
    margin-left: auto;
    clip-path: polygon(
      0 50%,
      calc(var(--arrow-px)) 0,
      calc(100% - var(--corner-px)) 0,
      100% var(--corner-px),
      100% calc(100% - var(--corner-px)),
      calc(100% - var(--corner-px)) 100%,
      calc(var(--arrow-px)) 100%
    );
    border-left: none;
}

.currently-playing .game .blank-slot h1{
    background-color: transparent;
    --color-primary-4-S:  #1a022a4f;
    --color-primary-4-E:  #1a022a8f;
  
    background-image: linear-gradient(to right, var(--color-primary-4-S), 50%, var(--color-primary-4-E));
    border: none;
}

.currently-playing .game:nth-child(even) .blank-slot h1{
    background-image: linear-gradient(to left, var(--color-primary-4-S), 50%, var(--color-primary-4-E));
}

.currently-playing .game .blank-slot h1:hover  {
    cursor: default;
}

/* DIALOG */
.currently-playing .dialog {
    background-color: var(--color-primary-2);
    border: 5px solid var(--color-primary-3);

    position: absolute;
    padding: var(--padding-px);

    /* font-size: 3em; */
    /* margin-top: 0.67em; */
    /* or */
    /* margin-top: 32.16px; */
    
    height: calc(100%);
    width: 20vw;
    
    --padding-px: var(--vertical-padding);
    --corner-px: 40px;
}


/* these calcs need to be more accurate I think */
.currently-playing .game:nth-child(even) .dialog {
    /* 10px in transform I think is .home padding */
    transform: translate(calc(50vw - (5vw + 10px)), 32.16px);
    padding-left: calc(5vw + 20px);
    padding-right: var(--corner-px);

    clip-path: polygon(
      0 0,
      calc(100% - var(--corner-px)) 0,
      100% var(--corner-px),
      100% calc(100% - var(--corner-px)),
      calc(100% - var(--corner-px)) 100%,
      0 100%
    );
}

/* these calcs need to be more accurate I think */
.currently-playing .game:nth-child(odd) .dialog {
    /* calc(50vw - (100% + 2*var(--padding-px)) + (5vw + var(--padding-px))) */
    transform: translate(calc(50vw - (100% + 2*var(--padding-px)) + (5vw + var(--padding-px))), 32.16px);
    padding-right: calc(5vw + 20px);
    padding-left: calc(var(--corner-px) + 20px);
    
  
    clip-path: polygon(
      var(--corner-px) 0,
      100% 0,
      100% 100%,
      var(--corner-px) 100%,
      0 calc(100% - var(--corner-px)),
      0 var(--corner-px)
    );
}

.currently-playing .dialog-items {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    justify-content: flex-start;
}

.currently-playing .dialog-items .input-wrapper {
    display: flex;
    font-size: 1.0em;
    margin-bottom: 10px;
    width: 100%;
}

.currently-playing .dialog-items .input-wrapper label {
    width: 35%;
    margin-right: 10px;
}

.currently-playing .dialog-items .input-wrapper input {
    width: 35%;
    align-self: flex-end;
}

/* Dialog Buttons */
.currently-playing .dialog-items .dialog-buttons {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    /* justify-content: flex-end; */

    /* height: 100%; */
}

.currently-playing .dialog-items .dialog-buttons button {
    font-size: 1rem;
    flex-grow: 1;
    flex-basis: 0;
    padding:  5px 0px;
    /* align-self: flex-end; */
}

.currently-playing .dialog-items .dialog-buttons a {
    /* font-size: 1rem; */
    flex-grow: 1;
    flex-basis: 0;
    /* border: 3px;
    border-style: groove;
    color: white;
    border-color: var(--color-primary-4); */
    /* align-self: flex-end; */
}

.currently-playing .dialog-items .input-wrapper.checkbox-input .toggler-slider {
    width: calc(var(--knob-width)*2 + 10px );
}

.currently-playing .dialog-buttons button.primary-button {
    background-color: var(--color-primary-4);
}
