.angle-small-left{
    background-image: url(/resources/angle-small-left.svg);
    display: block;
}
.angle-small-right{
    background-image: url(/resources/angle-small-right.svg);
    display: block;
}

i.up-arrow {
    background-image: url(/resources/up-arrow.svg);
    display: block;
}

i.down-arrow {
    background-image: url(/resources/down-arrow.svg);
    display: block;
}

i.cross {
    background-image: url(/resources/cross-svgrepo-com.svg);
    display: block;
}

i.fast-forward {
    background-image: url(/resources/fast-forward-button-svgrepo-com.svg);
    display: block;
}

i.back-arrow {
    background-image: url(/resources/back-arrow.svg);
    display: block;
}

i.zack {
    /* credit to Zack, @zackdrake on discord  */
    background-image: url(/resources/this_is_an_svg.svg.png);
    display: block;
    background-size: 52px;
    width: 52px;
    height: 52px;
}


i.plus {
    background-image: url(/resources/plus.svg);
    display: block;
}


.icon-size-52 {
    width: 52px;
    height: 52px;
}

.icon-size-48 {
    width: 48px;
    height: 48px;
}

.icon-size-42 {
    width: 42px;
    height: 42px;
}

.icon-size-32 {
    width: 32px;
    height: 32px;
}

.icon-size-27 {
    width: 27px;
    height: 27px;
}

.icon-size-24 {
    width: 24px;
    height: 24px;
}

.icon-size-20 {
    width: 16px;
    height: 16px;
}

.icon-size-16 {
    width: 16px;
    height: 16px;
}


.white-filter {
    filter:  invert(100%) sepia(0%) saturate(7500%) hue-rotate(346deg) brightness(95%) contrast(113%);
}

/* special */

label.checkmark:after {
    left: 75px;
    top: 7px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: 
    rotate(45deg)
}

label.checkmark:after {
    content: "";
    /* width: 100%; */
    margin: auto;
    margin-top: 2px;
    /* position: absolute; */
    display: none;
}

input:checked ~ .checkmark:after {
    display: block;
}