::-webkit-scrollbar {
  width: 9px;
}
  
::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: var(--color-primary-1);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary-2);
}


/* 
-webkit-scrollbar
-webkit-scrollbar-button
-webkit-scrollbar-track
-webkit-scrollbar-track-piece
-webkit-scrollbar-thumb
-webkit-scrollbar-corner
-webkit-resizer 
*/