/* Checkpoints */
.game-page .checkpoint-block {
    /* background-color: var(--color-primary-4); */
    /* border: 5px solid var(--color-primary-1); */
    --corner-px: 25px;
    /* padding: 20px; */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.game-page .checkpoint-header {
    display: flex;
    flex-direction: row;
}

.checkpoint-block .checkpoint-header h3{
    background-color: var(--color-secondary-1-0);
    border: 5px solid var(--color-secondary-1-4);

    --corner-px: 50px;
    padding: 20px;
    padding-left: var(--corner-px);
    width: calc(45vw - var(--corner-px));

    clip-path: polygon(
    0 0,
    calc(100% - var(--corner-px)) 0,
    100% 100%,
    var(--corner-px) 100%
  );
}

.checkpoint-block .checkpoint-header .new-checkpoint{
    /* padding: 20px; */
    width: auto;
    padding-left: var(--corner-px);
    padding-right: var(--corner-px);
}

.game-page .checkpoint-list {
    flex-grow: 1;
    overflow: auto;
    height: 100%;
}

/* Entry */
.game-page .checkpoint-entry {
    background-color: var(--color-secondary-1-4);
    --border-px: 5px;

    border: var(--border-px) solid var(--color-secondary-1-1);
    margin: 20px 10px;
    /* margin-bottom: 20px; */
    padding: 10px var(--clip-px);

    --clip-px: 50px;

    clip-path: polygon(
        var(--border-px) 50%,
        var(--clip-px) 0,
        calc(100% - var(--clip-px)) 0,
        calc(100% - var(--border-px)) 50%,
        calc(100% - var(--clip-px)) 100%,
        var(--clip-px) 100%
    );
    transition: all 100ms ease;
}

.game-page .checkpoint-entry.final{
    border-color: var(--color-primary-1);
}

.checkpoint-entry .checkpoint-title {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.checkpoint-entry .checkpoint-title .checkpoint-buttons{
    margin-left: auto;
    display: flex;
    width: 20%;
    position: relative;
}

.checkpoint-buttons button {
    padding: 10px;
    flex-grow: 1;
    width: 50%;
}

.checkpoint-text {
    white-space: pre-line;
}

.checkpoint-entry i{
    margin:auto
}

/* delete dialog */

.checkpoint-entry .confirm-dialog-wrapper {
    /* width: 100%; */
}

.checkpoint-entry .confirm-dialog {
    position: absolute;
    /* width: 100%; */
    width: 50%;
    transform: translate(0px,calc(100% - 12px));
    background-color: var(--color-secondary-1-0);
    border: 3px solid var(--color-secondary-1-1);
    box-sizing: border-box;
    border-top: 0px;
    /* border-right: 0px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 5px; */
    padding-top: 3px;
    /* z-index: 1; */
  
  
  
    --corner-cut: 15px;
  
     clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - var(--corner-cut)),
      calc(100% - var(--corner-cut)) 100%,
      var(--corner-cut) 100%,
      0 calc(100% - var(--corner-cut))
    );  
}

.checkpoint-entry .confirm-dialog button{
    width:100%
    /* width: 50%; */
  }