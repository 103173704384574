.home-page .game-stats {
    /* height: calc(100vh - 40px); */
    display: flex;
    flex-direction: column;
}

.game-stats .header-block {
    display: flex;
}

.game-stats .title h1 {
    border-color: var(--color-primary-1);
}
  
.game-stats .action-block h2 {
    border-color: var(--color-primary-1);
}
  
.game-stats .currently-playing-toggle{
    --slash-px:  50px;
    --corner-px: 20px;
  
    clip-path: polygon(
      var(--corner-px) 0,
      calc(100% - var(--slash-px)) 0,
      100% 100%,
      var(--corner-px) 100%,
      0 calc(100% - var(--corner-px)),
      0 var(--corner-px)
    );
  }
  
.game-stats .stats-toggle {
    --slash-px:  50px;
    --corner-px: 20px;
    clip-path: polygon(
      0 0,
      calc(100% - var(--corner-px)) 0,
      100% var(--corner-px),
      100% calc(100% - var(--corner-px)),
      calc(100% - var(--corner-px)) 100%,
      var(--slash-px) 100%
    );
}

.game-stats .stats-block {
    background-color: var(--color-primary-4);
    border: 5px solid var(--color-primary-1);
    padding: 20px;
    font-size: 1.2rem;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* flex-direction: row; */
    --corner-px: 20px;
    /* flex-grow: 1; */
}

.stats-block .label-parent{
    display: flex;
    justify-content: space-between;
    /* font-size: ; */
    width: 40%;
    margin-bottom: 20px;
}