.Games {
    height: 100vh;
}
  
.game-window {
  display: flex;
  flex-direction: column;
}

.panels {
  --panel-top-margin: 20px;
  display: flex;
  width: 100%;
  margin-top: var(--panel-top-margin);
}

.game-window-tabs {
    /* position: absolute; */
    /* width: calc(100vw - 10px); */
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-evenly;
    /* justify-content: space-between; */

    background-color: var(--color-primary-4);
    /* border-bottom: 5px solid var(--color-primary-1); */
  }
  
.game-window-tabs button {
  border-color: var(--color-primary-1);
  border-style:groove;
  border-width: 0 0 10px 0;
  font-size: 1.2em;
  flex-grow: 1;

  /* border-bottom: 10px; */
  /* width: 15vw; */
  /* height: 30px; */
  padding:10px;
  padding-top: 15px;
  
  background:transparent

}

.game-window-tabs button.active {
  /* background-color: var(--color-primary-4); */
  color:  var(--color-secondary-1-1) ;
  border-color: var(--color-secondary-1-1);
}

.game-window-tabs a {
  border-color: var(--color-primary-1);
  border-style:groove;
  border-width: 0 0 10px 0;
  width: 32px;
  height: 27px;
  padding: 10px;
}

.game-window-tabs a:hover {
  filter: brightness(1.3);
}

.game-list-panel {
  --padding-px: 5px;
  width: 30vw;
  margin-left: 20px;
  margin-bottom: 10px;
  /* margin-top: 50px; */
  /* padding: var(--padding-px); */
  height: 100%;
}

.create-game-panel-shadow {
  filter: 
    drop-shadow( 3px 4px 2px black)
    drop-shadow( 3px 4px 2px black)
    drop-shadow( -1px -1px 0px black)
}

/* Game Display */

.game-display-panel {
  /* 10px Margin Bottom, 5px padding  5pxmargin tabs, 10*2px padding tabs buttons*/
  /*  I think */
  /*height: calc(100vh - var(--panel-top-margin) - 10px - (5px * 2) -5px -20px);*/
  overflow: auto;

  width: 40vw;
  margin-right: 20px;
  margin-top: 0px; 
  margin-bottom: 10px; 
  margin-left:auto;
  padding: 5px;
  position: relative;
}

/* Create Game */

.create-game-panel {
  --padding-px: 25px;
  --border-px: 5px;
  --margin-px: 20px;
  min-width: 20vw;
  height: calc(50vh - var(--panel-top-margin) - var(--padding-px) - var(--border-px) );
  margin-left:  var(--margin-px);
  margin-right: var(--margin-px);
  margin-bottom: 10px;
  color: white;
  background-color: var(--color-primary-4);
  padding: var(--padding-px);
  

  border: var(--border-px);
  border-style: solid;
  border-color: var(--color-primary-1);

  --corner-px: 25px;
    clip-path: polygon(
      var(--corner-px) 0%,
      calc(100% - var(--corner-px)) 0%,
      100% var(--corner-px),
      100% calc(100% - var(--corner-px)),
      calc(100% - var(--corner-px)) 100%,
      var(--corner-px) 100%,
      0% calc(100% - var(--corner-px)),  
      0% var(--corner-px)
    );
}


/* image modal */
.game-window .modal-wrapper {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  top: 0;
  display: flex;
  backdrop-filter: blur(10px);
}

.game-window .full-size-image-modal {
  position: relative;
  margin: auto;
  /* background-color: var(--color-primary-4); */
  /* height: 85vh; */
  width: 75vw;
  display: flex;
  flex-direction: column;
  /* padding: 20px; */
  /* justify-content: center; */
}

.game-window .full-size-image-modal img {
  align-self: flex-start;
  margin: 0 auto;
  /* display: block; */
  /* width: 90%; */

  width: 100%;
}

.game-window .full-size-image-modal .modal-buttons {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-self: flex-end;
  /* margin-left: auto; */
  /* margin-right: auto; */
  margin-top: 20px;
  width: 50%;
  /* position: absolute; */
  /* bottom: 0; */
}

.full-size-image-modal button {
  padding:5px
}

.full-size-image-modal .confirm-dialog {
  background-color: var(--color-primary-4);
  border: 3px solid var(--color-primary-1);
  border-top: 0px;
  display: flex;
  flex-direction: column;
  /* width: 80%; */
  align-items: center;
  padding: 5px;


  /* text-align: center; */


  --corner-cut: 15px;

  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--corner-cut)),
    calc(100% - var(--corner-cut)) 100%,
    var(--corner-cut) 100%,
    0 calc(100% - var(--corner-cut))
  ); 
}

.full-size-image-modal .confirm-dialog button{
  width:80%
}
