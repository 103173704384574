.game-page {
    padding: 20px;
    display: flex;
    /* change this later, if using flexbox  still :D */
    height: calc(100vh - 40px);
    flex-direction: column;
}

.header, .checkpoint-block, .info-block-shadow {
    filter: 
    drop-shadow( 0px 5px 3px black)
    drop-shadow( 0px 5px 3px black);
}

.game-page .header {
    display: flex;
    flex-direction: row;
}

.game-page .header .back-button{
/* height: 50%; */  
    width: auto;
    padding-left: var(--corner-px);
    padding-right: var(--corner-px);}

.game-page .info-block {
    background-color: var(--color-primary-4);
    border: 5px solid var(--color-primary-1);
    --corner-px: 25px;

    display: flex;
    flex-direction: column;

    padding: 20px;
    margin-bottom: 20px;
}

.info-block .game-info{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
    font-size: 1.2rem;
}

.info-block hr {
    border: 1px solid var(--color-primary-3);
    width: 100%;
}

.info-block .info-buttons {
    margin-left: auto;
    display: flex;
    width: 20%;
}

.info-block .info-buttons button{
    font-size: 1.2rem;
    margin-left: auto;
    width: 50%;
}

.info-block .game-field {
    display: flex;
    justify-content: space-between;
    width: 30%;
    margin-bottom: 10px;
    height: 30px;
}

.info-block .game-field .input{
    width: 30%;
}

.info-block .game-field input,select{
    font-size: 1.05rem;
    background-color: var(--color-primary-2);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.info-block .game-field select{
    border: 2px solid black;
}

.info-block input[type="checkbox"]+label {
    border: 2px solid black;
    padding: 2px;
    background-color: var(--color-secondary-2-4);
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
}

.info-block input[type="checkbox"]:checked+label {
    background-color: var(--color-primary-1);
}


/*  Checkpoint Modal */
.game-page .modal-wrapper {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    backdrop-filter: blur(10px);
}

.game-page .modal {
    background-color: var(--color-secondary-1-4);
    border: 5px solid var(--color-secondary-1-1);
    height: 80vh;
    width: 80vw;
    margin:auto;
    padding:25px;
}

.checkpoint-modal form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.checkpoint-modal .input-wrapper {
    display: flex;
    font-size: 1.3rem;
    width: 100%;
    /* height: 10%; */
    margin-bottom: 10px;
}

.checkpoint-modal .input-wrapper label{
    width: 10%;
    margin-right: 10px;
}

.checkpoint-modal .input-wrapper input {
    width: 30%;
    font-size: 1.2rem;
    /* align-self: flex-end; */
}

.checkpoint-modal .input-wrapper.text-area {
    height: 60%;
}

.checkpoint-modal .input-wrapper textarea {
    width: 80%;
    height: 100%;
    font-size: 1.2rem;
    resize: vertical;
}

.checkpoint-modal .control-buttons {
    margin-top: auto;
    margin-left: auto;
    display: flex;
    width: 20%;
}

.checkpoint-modal .control-buttons button{
    font-size: 1.5rem;
    width: 50%;
}

